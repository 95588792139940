import React from "react";

export const Title3 = () => {
    return (
        <>
            <h1
            className="mb-3 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl"
            itemProp="headline"
            title="Rise of Tailwind - A Utility First CSS Framework"
            >
                Dapetin cash! Sewakan barang tak terpakai di sekitar mu!
            </h1>
        </>
    )
}

export const DataPost3 = () => {
    return (
        <>
            <p className="text-base text-gray-500">
                November 13, 2024 — Written by Pipopa
            </p>
        </>
    )
}

export const HeadParagraph3 = () => {
    return (
        <>
            <p>
            Apakah Anda memiliki barang di sekitar yang jarang terpakai? tahukah Anda ada ribuan orang dekat anda yang saat ini mungkin membutuhkan barang tersebut tetapi belum memutuskan apakah mereka harus beli atau bisa pinjam? Dengan Pipopa kamu bisa dapat penghasilan dengan meminjam kan barang yang kamu punya saat ini tapi jarang di gunakan.
            </p>
        </>
    )
}

export const Image3 = () => {
    return (
        <>
            <img
          src="/uang.jpg"
          className="object-cover w-full h-64 bg-center rounded"
          alt="Kutty"
        />
        </>
    )
}

export const Content3 = () => {
    return (
        <>
            <section className="p-8 text-gray-800">
                <h2 className="text-2xl font-bold mb-4">Apakah Anda memiliki barang di sekitar yang jarang terpakai?</h2>
                <p className="mb-6">
                    Tahukah Anda ada ribuan orang dekat anda yang saat ini mungkin membutuhkan barang tersebut tetapi belum memutuskan apakah mereka harus beli atau bisa pinjam? 
                    Dengan Pipopa kamu bisa dapat penghasilan dengan meminjamkan barang yang kamu punya saat ini tapi jarang digunakan.
                </p>

                <h3 className="text-xl font-semibold mb-4">Keuntungan Menyewakan Barang</h3>
                <ul className="space-y-2 list-disc list-inside mb-8">
                    <li><strong className="font-semibold">Dapatkan penghasilan tambahan:</strong> Mencapai penghasilan utama dengan menyewakan barang-barang yang Anda punya ke orang sekitar yang membutuhkan.</li>
                    <li><strong className="font-semibold">Buat lingkungan lebih rapi:</strong> Rasakan sekitar Anda lebih lega tanpa barang tak terpakai.</li>
                    <li><strong className="font-semibold">Kurangi menumpuk barang:</strong> Ciptakan lingkungan dengan asas manfaat.</li>
                    <li><strong className="font-semibold">Beri kesempatan bagi orang lain:</strong> Bantu orang mencoba barang sebelum mereka memutuskan untuk membeli.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-4">Mulai Sekarang! Caranya Mudah</h3>
                <ol className="space-y-3 list-decimal list-inside mb-8">
                    <li><strong className="font-semibold">Kelompokan barang-barang:</strong> Identifikasi barang di sekitar Anda yang masih berfungsi dan bisa disewakan.</li>
                    <li><strong className="font-semibold">Listing di Pipopa:</strong> Buat akun Lessor, unggah foto, tulis deskripsi lengkap, dan tentukan harga sewa (Jam/Hari/Minggu/Bulan/Tahun).</li>
                    <li><strong className="font-semibold">Pipopa menemukan penyewa:</strong> Pipopa akan menemukan orang yang membutuhkan barang Anda dan membantu proses transaksi yang aman dan mudah.</li>
                </ol>

                <p className="mb-4">
                    Rasakan kesempatan mendapatkan penghasilan tambahan tak terduga hanya dengan meminjamkan barang di sekitar Anda. 
                    Bersama Pipopa, sewa-menyewa barang jadi mudah, nyaman, dan memberi kepuasan tersendiri.
                </p>
            </section>
        </>
    )
}